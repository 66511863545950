import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import "./styles/style.scss";
import Nav from './components/Nav';
import AboutMe from './components/AboutMe';
import Contact from './components/Contact';
import Services from './components/Services';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faCircleCheck, faCircleXmark, faClock, faMobileScreen, faMoneyBill, faMoneyBill1, faSquareCheck, faSquareEnvelope} 
from '@fortawesome/free-solid-svg-icons';

import { faAt } from '@fortawesome/free-solid-svg-icons';
import { faRectangleXmark } from '@fortawesome/free-regular-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import WebdevCourse from './components/WebdevCourse';
import SqlCourse from './components/SqlCourse';
library.add(
    faMobileScreen, faAt, 
    faSquareEnvelope, 
    faMoneyBill, faMoneyBill1, 
    faFacebook, faRectangleXmark,
    faCircleXmark, faSquareCheck,
    faBars, faCircleCheck,
    faClock
);

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Nav/>}>
                    <Route index element={<AboutMe/>}/>
                    <Route path="szolgaltatasaim" element={<Services/>}/>
                    <Route path="kapcsolat" element={<Contact/>}/>
                    <Route path='webfejlesztes-kurzus' element={<WebdevCourse/>}/>
                    <Route path='mysql-kurzus' element={<SqlCourse/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
