import ContactLine from "./ContactLine";
import Img from "./Img";
import Service from "./Service";
import TheySaid from "./TheySaid";

function AboutMe() {
    return (
        <>
            <div className="container box">
                <h1>Rólam egy pár szóban</h1>

                <div>
                    {<Img bordered="true" width="200" height="200" side="left" src={require("../images/me.png")}/>}

                    <p>
                        Kovács Olivér vagyok, szabadúszó programozó és oktató. Azért fogtam bele az oktatásba, 
                        mert szeretném átadni a tudásomat a tanulni vágyóknak. Lehetne olyan közhelyekkel 
                        folytatni, hogy a programozás ma már az élet minden területén jelen van, de az a 
                        helyzet, hogy ezt valószínűleg már te is tudod. Én inkább úgy fogalmaznék, hogy 
                        jó dolog valami olyasmit tudni, ami ennyire átszövi az egész világot, mert 
                        bepillantást nyerhetünk a körülöttünk lévő folyamatokba, egy kicsit többet 
                        tudhatunk meg arról a környezetről, amely körbevesz bennünket. Ha gondolkodtál 
                        már azon, hogy miként jut el a pénzed egyik helyről a másikra, vagy hogyan tudsz 
                        üzenni valakinek az interneten keresztül, akkor a kellő kíváncsiságod már 
                        ki is alakult a tanuláshoz.
                    </p>

                    <p>
                        Nem utolsó sorban programozóként elég jól lehet keresni, és tekintettel arra, 
                        hogy ma már százmilliós nagyságrendű weboldal létezik, így mindig keresni fognak 
                        olyat, aki elkészíti a következőt. Igyekszem érthetően elmagyarázni azt, amit tudnod 
                        kell a témakörről, az alapokból a weblap biztonságon keresztül a teljesítményproblémákig. 
                        Megtanítalak hogyan tudsz felépíteni egy weboldalt a semmiből. Ez a tanulási folyamat 
                        nagyon érdekes is lehet, szerintem érdemes belevágni kortól függetlenül. Nem baj, ha 
                        nem 16 évesen kezdesz programozni, hanem 40 évesen. Senki nem fog hülyének nézni 
                        miatta, én végképp nem. 
                    </p>
                </div>
            </div>
            {<TheySaid/>}

            <div className="container box">
                <div className="row text-center">
                    <Service img={require("../images/html.png")} serviceName="HTML5" 
                    desc="A HTML (HyperText Markup Language) képzi a weboldalak vázát. Ez a struktúra, amelyre minden design épül."/>

                    <Service img={require("../images/css.png")} serviceName="CSS3" 
                    desc="A CSS (Cascading Style Sheet) felel a honlapok dizájnjáért, például a színekért és a betűtípusokért."/>

                    <Service img={require("../images/javascript.png")} serviceName="JavaScript" 
                    desc="A JavaScript egy úgynevezett kliens oldali nyelv. A felhasználóval történő interakciók kezelése a dolga."/>

                    <Service img={require("../images/php.png")} serviceName="PHP" 
                    desc="A PHP egy szerveroldali scriptnyelv, amely arra hivatott, hogy a webalkalmazások magját képezze."/>

                    <Service img={require("../images/mysql.png")} serviceName="MySQL" 
                    desc="A MySql egy relációs adatbázisszerver, amely kiválóan alkalmas webalkalmazásokkal való együttműködésre."/>

                    <Service img={require("../images/hashtag.png")} serviceName="C#" 
                    desc="A C# egy tisztán objektumorientált, általános célú programozási nyelv, amely alkalmas akár asztali programok, akár mobilalkalmazások vagy weboldalak elkészítésére."/>

                    <Service img={require("../images/react.png")} serviceName="React" 
                    desc="A React egy kliensoldali JavaScript framework, amelynek segítségével interaktív webes felületek hozhatóak létre."/>

                    <Service img={require("../images/nodejs.png")} serviceName="NodeJS" 
                    desc="A Node.js egy nyílt forráskódú, szerveroldali futtatási környezet, amely lehetővé teszi a JavaScript használatát a kiszolgáló oldalon is."/>
                </div>
            </div>

            {<ContactLine bgcolor="bg-dark-grey"/>}
        </>
    );
}

export default AboutMe;