import { MbContext } from "./Nav";
import { useContext, useRef, useState } from "react";
import MessageBox from "./MessageBox";
import { sBaseUrl } from "../app/url";

function ContactForm({ subject }) {
    let [name, setName] = useState("");
    let [email, setEmail] = useState("");
    let [message, setMessage] = useState("");
    const mb = useContext(MbContext);
    const formElement = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();

        const emailObj = {
            name: name,
            email: email,
            message: message,
            subject: subject
        };

        let respMessages = null;

        try {
            const response = await fetch(`${sBaseUrl}/email`, {
                method: "POST",
                body: JSON.stringify(emailObj),
                headers: { "content-type": "application/json" }
            });

            respMessages = await response.json();
            console.log(respMessages);

            mb.setMessages(response.ok ? respMessages.message : respMessages.errors);
            mb.setDisplayMb(true);

            if (response.ok)
                formElement.current.reset();
        } catch (err) {
            console.log(err);
            //const data = await response.text();
        }
    };

    return (
        <>
            <MessageBox
                messages={mb.messages}
                display={mb.displayMb}
                setDisplay={mb.setDisplayMb}
                buttons={mb.buttons}
            />

            <form className="text-center" ref={formElement}>
                <h3>Név</h3>
                <input className="center-input w90"
                    onChange={(e) => setName(e.target.value)}
                    placeholder="név" />

                <h3>Email cím</h3>
                <input className="center-input w90"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="email cím" />

                <h3>Üzenet</h3>
                <textarea className="center-input w90 mih200"
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="üzenet"></textarea>

                <button onClick={sendEmail} className="btn">Üzenet küldése!</button>
            </form>
        </>
    );
}

export default ContactForm;